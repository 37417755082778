import { css as h, LitElement as l, html as c } from "lit";
import { createRef as f, ref as p } from "lit/directives/ref.js";
import n from "@monaco-editor/loader";
let d = "https://cdn.jsdelivr.net/npm/monaco-editor@0.39.0/min/vs";
function S(s) {
  d = s;
}
function r() {
  return self.__MONACO_VS_PATH__ ?? d;
}
const m = h`
  :host {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  [part~='inner-container'] {
    all: initial;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;
class u extends l {
  constructor() {
    super(...arguments), this.innerContainerRef = f();
  }
  firstUpdated() {
    this.initializeEditor();
  }
  shouldUpdate(t) {
    return t.forEach((o, e) => {
      var i, a;
      switch (e) {
        default:
          (a = (i = this.PROPERTY_CHANGE_HANDLER_DICT)[e]) == null || a.call(
            i,
            this[e]
          );
          break;
      }
    }), !0;
  }
  render() {
    return c`<div
      ${p(this.innerContainerRef)}
      part="inner-container"
    ></div>`;
  }
  async initializeEditor() {
    await this.loadEditorStyles(), await this.loadMonaco(), await this.loadEditor(this.innerContainerRef.value), this.defineEvents();
  }
  async loadMonaco() {
    const t = r();
    t && n.config({
      paths: {
        vs: t
      }
    }), this.monaco = await n.init();
  }
  async loadEditorStyles() {
    const t = r(), o = new CSSStyleSheet(), e = await fetch(`${t}/editor/editor.main.css`);
    if (e.ok) {
      const i = await e.text();
      await o.replace(i);
    }
    this.shadowRoot && (this.shadowRoot.adoptedStyleSheets = [
      ...this.shadowRoot.adoptedStyleSheets,
      o
    ]);
  }
  defineEvents() {
    this.dispatchEvent(
      new CustomEvent(
        "editorInitialized",
        {
          detail: { monaco: this.monaco, editor: this.editor },
          bubbles: !0,
          composed: !0,
          cancelable: !0
        }
      )
    );
  }
}
u.styles = m;
export {
  u as E,
  r as g,
  S as s
};
