import { property as l, customElement as p } from "lit/decorators.js";
import { createRef as d } from "lit/directives/ref.js";
import { E as h } from "./editor-base-d9669885.js";
import "lit";
import "@monaco-editor/loader";
var c = Object.defineProperty, g = Object.getOwnPropertyDescriptor, s = (t, e, o, r) => {
  for (var i = r > 1 ? void 0 : r ? g(e, o) : e, n = t.length - 1, u; n >= 0; n--)
    (u = t[n]) && (i = (r ? u(e, o, i) : u(i)) || i);
  return r && i && c(e, o, i), i;
};
let a = class extends h {
  constructor() {
    super(...arguments), this.PROPERTY_CHANGE_HANDLER_DICT = {
      value: (t) => {
        var e, o;
        t !== ((e = this.editor) == null ? void 0 : e.getValue()) && ((o = this.editor) == null || o.setValue(t ?? ""));
      },
      language: (t) => {
        var o, r;
        const e = (o = this.editor) == null ? void 0 : o.getModel();
        e && ((r = this.monaco) == null || r.editor.setModelLanguage(e, t ?? ""));
      },
      options: (t) => {
        var e;
        return (e = this.editor) == null ? void 0 : e.updateOptions(t ?? {});
      }
    }, this.editorContainerRef = d();
  }
  async loadEditor(t) {
    var e, o;
    t && (this.editor = (e = this.monaco) == null ? void 0 : e.editor.create(t, {
      value: this.value,
      language: this.language,
      automaticLayout: !0,
      fontLigatures: ""
      // TODO This is temporary fix for the incorrect cursor position
    }), (o = this.editor) == null || o.updateOptions(this.options ?? {}));
  }
  defineEvents() {
    var t;
    super.defineEvents(), (t = this.editor) == null || t.onDidChangeModelContent((e) => {
      var o;
      this.value = (o = this.editor) == null ? void 0 : o.getValue(), this.dispatchEvent(
        new CustomEvent("didChangeModelContent", {
          detail: e,
          bubbles: !0,
          composed: !0,
          cancelable: !0
        })
      );
    });
  }
};
s([
  l({ attribute: "value", reflect: !0 })
], a.prototype, "value", 2);
s([
  l({ attribute: "language", reflect: !0 })
], a.prototype, "language", 2);
s([
  l()
], a.prototype, "options", 2);
a = s([
  p("hey-monaco-editor")
], a);
export {
  a as HeyMonacoEditor
};
