import { property as n, customElement as f } from "lit/decorators.js";
import { createRef as g } from "lit/directives/ref.js";
import { E as u } from "./editor-base-d9669885.js";
import "lit";
import "@monaco-editor/loader";
var m = Object.defineProperty, p = Object.getOwnPropertyDescriptor, s = (e, i, t, r) => {
  for (var o = r > 1 ? void 0 : r ? p(i, t) : i, a = e.length - 1, l; a >= 0; a--)
    (l = e[a]) && (o = (r ? l(i, t, o) : l(o)) || o);
  return r && o && m(i, t, o), o;
};
let d = class extends u {
  constructor() {
    super(...arguments), this.PROPERTY_CHANGE_HANDLER_DICT = {
      original: (e) => {
        var i, t;
        e !== ((i = this.originalModel) == null ? void 0 : i.getValue()) && ((t = this.originalModel) == null || t.setValue(e ?? ""));
      },
      originalLanguage: (e) => {
        var i;
        this.originalModel && ((i = this.monaco) == null || i.editor.setModelLanguage(this.originalModel, e ?? ""));
      },
      modified: (e) => {
        var i, t;
        e !== ((i = this.modifiedModel) == null ? void 0 : i.getValue()) && ((t = this.modifiedModel) == null || t.setValue(e ?? ""));
      },
      modifiedLanguage: (e) => {
        var i;
        this.modifiedModel && ((i = this.monaco) == null || i.editor.setModelLanguage(this.modifiedModel, e ?? ""));
      },
      options: (e) => {
        var i;
        return (i = this.editor) == null ? void 0 : i.updateOptions(e ?? {});
      }
    }, this.editorContainerRef = g();
  }
  async loadEditor(e) {
    var i, t, r, o, a;
    e && (this.originalModel = (i = this.monaco) == null ? void 0 : i.editor.createModel(
      this.original ?? "",
      this.originalLanguage
    ), this.modifiedModel = (t = this.monaco) == null ? void 0 : t.editor.createModel(
      this.modified ?? "",
      this.modifiedLanguage
    ), this.editor = (r = this.monaco) == null ? void 0 : r.editor.createDiffEditor(e, {
      automaticLayout: !0,
      fontLigatures: ""
      // TODO This is temporary fix for the incorrect cursor position
    }), this.originalModel && this.modifiedModel && ((o = this.editor) == null || o.setModel({
      original: this.originalModel,
      modified: this.modifiedModel
    })), (a = this.editor) == null || a.updateOptions(this.options ?? {}));
  }
  defineEvents() {
    var e;
    super.defineEvents(), (e = this.editor) == null || e.onDidUpdateDiff(() => {
      this.dispatchEvent(
        new CustomEvent("didUpdateDiff", {
          bubbles: !0,
          composed: !0,
          cancelable: !0
        })
      );
    });
  }
};
s([
  n({ attribute: "original", reflect: !0 })
], d.prototype, "original", 2);
s([
  n({ attribute: "original-language", reflect: !0 })
], d.prototype, "originalLanguage", 2);
s([
  n({ attribute: "modified", reflect: !0 })
], d.prototype, "modified", 2);
s([
  n({ attribute: "modified-language", reflect: !0 })
], d.prototype, "modifiedLanguage", 2);
s([
  n()
], d.prototype, "options", 2);
d = s([
  f("hey-monaco-diff-editor")
], d);
export {
  d as HeyMonacoDiffEditor
};
